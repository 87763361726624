<template>
    <div class="card-body" v-if="isReady">
        <p class="header-title">
            {{ $t("Faq info") }}
        </p>
        <!-- <div v-if="isEditMode" class="form-group mb-3 d-none">
            <label for="">{{ $t("Select language") }}</label>
            <v-select v-model="form.locale" :options="languages" label="name"></v-select>
        </div> -->
        <div v-for="(faq, index) in form.faqs" :key="index">
            <p
                class="d-flex align-items-center justify-content-between single-faq"
            >
                <a
                    data-bs-toggle="collapse"
                    :href="`#faq${index + 1}`"
                    role="button"
                    :aria-expanded="index == 0 ? 'true' : 'false'"
                    :aria-controls="`faq${index + 1}`"
                >
                    <span v-if="faq.title">{{ faq.title }}</span>
                    <span v-else
                        >Faq
                        {{ index + 1 }}
                    </span>
                </a>

                <a
                    v-if="index != 0"
                    @click.prevent="removeFaq(faq)"
                    class="text-danger text-bold"
                    href="#"
                    ><i class="ti-close"></i
                ></a>
            </p>
            <div
                class="collapse"
                :class="index == 0 ? 'show' : ''"
                :id="`faq${index + 1}`"
            >
                <div class="row">

                    <!-- input type hidden -->
                    <InputGroup
                        v-model="faq.type"
                        type="hidden"
                        :index="index"
                        :name="`faqs[${index}][type]`"
                        
                        placeholder="Faq type"
                        :errors="errors"
                    />
                    <InputGroup
                        v-model="faq.id"
                        type="hidden"
                        :index="index"
                        :name="`faqs[${index}][id]`"
                        
                        placeholder="Faq type"
                        :errors="errors"
                    />



                    <InputGroup
                        class="col-xl-12 col-lg-12 p-2"
                        v-model="faq.question"
                        :index="index"
                        label="Faq questions"
                        :name="`faqs[${index}][question]`"
                        placeholder="Faq questions"
                        :errors="errors"
                    />
                    <div class="form-group col-xl-12 col-lg-12 p-2">
                        <label> {{ $t("Faq answer") }}</label>
                        <!-- {{ $trans(data.model, faq.id, data.locale, 'answer', faq.answer) }} -->
                        <textarea :name="`faqs[${index}][answer]`" v-model="faq.answer" class="form-control"></textarea>

                        <input-errors
                            :name="`faqs.${index}.answer`"
                            :errors="errors"
                        ></input-errors>
                    </div>

                </div>

                <div class="form-group col-xl-6 col-lg-6 p-2 mb-2">
                    <input-errors
                        :name="`faqs.${index}.status`"
                        :errors="errors"
                    ></input-errors>
                </div>
            </div>
        </div>
        <p v-if="!isEditMode">
            <a @click.prevent="addNewFaq" class="btn btn-sm btn-info" href="#"
                >{{ $t("Add faq") }}</a
            >
        </p>

        <!-- submit buttons -->
        <div class="form-group" v-if="data.faqType != 'course'">
            <div>
                <button
                    class="btn btn-primary waves-effect waves-lightml-2 ic_mr-2"
                    type="button"
                    @click.prevent="submitAction"
                >
                    <i class="fa fa-save"></i> {{ $t("Submit") }}
                </button>
                <a
                    class="btn btn-danger waves-effect"
                    :href="route('faqs.index')"
                >
                    <i class="fa fa-times"></i> {{ $t("Cancel") }}
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import inputGroup from "@/components/partials/inputGroup.vue";
import { notifyMe, initiateTranslate, removeSessionTranslateData } from "@/helpers";
import { v4 as uuidv4 } from "uuid";
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import InputErrors from "../partials/InputErrors.vue";


export default {
    components: { inputGroup, InputErrors, vSelect },
    props: ["data", "old_faq", "course_faqs", "type"],
    data() {
        return {
            errors: {},
            isReady: false,
            languages:[
                {
                    name:'English',
                    code:'en',
                },
                {
                    name:'Bangla',
                    code:'bn',
                },
            ],
            dataEmptyFaq: {
                id: uuidv4(),
                question: "",
                type: this?.data.faqType,
                answer: "",
                course_id: this?.data?.course_id,
            },
            form: {
                faqs     : [],
                course_id: this?.data?.course_id,
                locale   : this.data.locale ? this.data?.locale: 'en',
            },
        };
    },

    methods: {
        // add new empty faq
        addNewFaq() {
            this.dataEmptyFaq.id = uuidv4();
            this.form.faqs.push({ ...this.dataEmptyFaq });
        },

        removeFaq(faq) {
            this.form.faqs.splice(this.form.faqs.indexOf(faq), 1);
        },

        // action submit
        async submitAction() {
            const data = this.form;
            const self = this;
            let actionUrl = route("faqs.store");
            let method = "post";

            if (this.isEditMode) {
                actionUrl = route("faqs.update", this.old_faq?.id);
                method = "put";
            }
            await axios[method](actionUrl, data)
                .then((res) => {
                    const { status, message, data } = res.data;
                    if (data) {
                        this.form.faqs = [...data?.faqs];
                    }
                    
                    if (status == "success") {
                        notifyMe(message, "success");
                        const redirectUrl = route("faqs.index");
                        window.location.href = redirectUrl;
                    }
                })
                .catch(function (error) {
                    const { data: allErrors = {} } = error?.response?.data;
                    self.errors = allErrors;
                });
        },

        // async langChangeHandler(){
            
        //     const self = this;
        //     await axios.get(route('faqs.lang.data', {id:this?.old_faq?.id, code:this.form.locale.code})).then((res) => {
        //         const { status, message, data } = res.data;
        //         if (status == "success") {
        //             this.form.faqs = [{...data}];
        //         }
        //     })
        //     .catch(function (error) {
        //         const { data: allErrors = {} } = error?.response?.data;
        //         self.errors = allErrors;
        //     });
        // },
    },

    async mounted() {
        if (!this.isEditMode) {
            if (this.course_faqs && this.course_faqs.length > 0) {
                this.form.faqs = [...this.course_faqs];
            }
            else{
                this.addNewFaq();
            }
        } else {
            this.form.faqs.push({ ...this?.old_faq });
        }

        if (this.data.locale && this.data.locale != 'en') {
            const res = await initiateTranslate(this.data.model, this.data.locale);
            if (res) {
                this.form.faqs.forEach(faq => {
                    faq.answer = this.$trans(this.data.model, faq.id, this.data.locale, 'answer', faq.answer);
                    faq.question = this.$trans(this.data.model, faq.id, this.data.locale, 'question', faq.question);
                });
                this.isReady = true;
            }
        }else{
            this.isReady = true;
        }

        
    },
    computed: {
        isEditMode: function () {
            if (this?.data?.course_id) {
                return false;
            }else{
                if (this?.old_faq?.id || (this.course_faqs && this.course_faqs.length > 0)) {
                    return true;
                } else {
                    return false;
                }
            }
        },
    },

    // watch:{
    //     'form.locale': function (newVal, oldVal){
    //        this.langChangeHandler();
    //     },
    // },
    deactivated: function() {
        removeSessionTranslateData()
        console.log("deactivate()")
    },
};
</script>
<style scoped>
.single-faq {
    background: #edf4f0;
    padding: 0.6rem;
    border-radius: 5px;
}
</style>
