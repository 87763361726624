<template>
    <div ref="textarea"></div>
</template>

<script>
import "https://cdnjs.cloudflare.com/ajax/libs/summernote/0.8.20/summernote.min.js";
// import "https://cdn.jsdelivr.net/npm/summernote@0.8.18/dist/summernote-lite.min.js?hadi";
import "https://code.jquery.com/ui/1.12.1/jquery-ui.js";
import katex from "katex";
import events from "./events";
if (!window.SUMMERNOTE_DEFAULT_CONFIGS) {
    window.SUMMERNOTE_DEFAULT_CONFIGS = {};
}
// window.summernote = summernote;

export default {
    props: {
        modelValue: {
            default: null,
            required: false,
            event: "change",
            validator(value) {
                return (
                    value === null ||
                    typeof value === "string" ||
                    value instanceof String
                );
            },
        },
        // https://summernote.org/deep-dive/
        config: {
            type: Object,
            default: () => window.SUMMERNOTE_DEFAULT_CONFIGS,
        },
    },

    data() {
        return {
            editorElement: "",
            defaultConfig: {
                placeholder: "Write here..",
                toolbar: [
                    ["style", ["bold", "italic", "underline", "clear"]],
                    ["font", ["strikethrough", "superscript", "subscript"]],
                    ["para", ["ul", "ol"]],
                    ["insert", ["picture", "equation"]],
                    ["view", ["codeview"]],
                ],
                buttons: {
                    equation: (context) => {
                        let ui = $.summernote.ui;
                        let button = ui.button({
                            contents: '<i class="fas fa-square-root-alt"/>',
                            tooltip: "Equation",

                            click: () => {
                                // invoke insertText method with 'hello' on editor module.
                                let div = document.createElement("div");
                                let text = prompt("Enter equation:");

                                if (text && text.length > 0) {
                                    var mathText = katex.renderToString(text, {
                                        throwOnError: false,
                                    });
                                    // const searchRegExp = /class="katex-html"/gi;
                                    // let outputhtml = mathText.replaceAll(searchRegExp, 'style="display:none"');
                                    let outputHtml =
                                        this.removeKatexHtml(mathText);

                                    context.invoke(
                                        "editor.pasteHTML",
                                        outputHtml
                                    );
                                }
                            },
                        });
                        return button.render();
                    },
                },
            },
        };
    },

    methods: {
        onChange(event) {
            const value = $(this.editorElement).summernote("code");
            this.$emit("update:modelValue", value);
        },

        removeKatexHtml(htmlString) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlString, "text/html");
            const elements = doc.querySelectorAll(".katex-html");

            elements.forEach((element) => {
                element.parentNode.removeChild(element);
            });

            return doc.body.innerHTML;
        },

        registerEvents() {
            // let __this =  this;
            for (var realName in events) {
                this.editorElement.on(`${realName}`, (...args) => {
                    this.$emit(`${events[realName]}`, ...args);
                });
            }
        },
    },

    watch: {
        modelValue(newValue) {
            const currValue = $(this.editorElement).summernote("code");
            if (newValue != currValue) {
                $(this.editorElement).summernote("code", newValue);
            }
        },
    },

    async mounted() {
        await this.$nextTick(() => {
            let _this = this;

            (function ($, _this) {
                //Check for the text editor
                if ($.fn.summernote) {
                    _this.editorElement = $(_this.$refs.textarea); // I like to use refs instead of $(selector)
                    //Init the Awesome Text Editor
                    let params = Object.assign(
                        _this.defaultConfig,
                        _this.config
                    );
                    _this.editorElement.summernote(params);

                    $(_this.editorElement).on(
                        "summernote.change",
                        _this.onChange
                    );
                    if (_this.modelValue) {
                        $(_this.editorElement).summernote(
                            "code",
                            _this.modelValue
                        );
                    }
                    _this.registerEvents();
                } else {
                    console.log(
                        "The text editor is not loaded properly for the content.  Please contact an administrator."
                    );
                }
            })(jQuery, _this); // init jquery
        });
    },

    /**
     * Free up memory
     */

    beforeUnmount() {
        let _this = this;
        (function ($, _this) {
            if ($.fn.summernote) {
                _this.editorElement.summernote("destroy");
            }
        })(jQuery, _this);
    },
};
</script>
<style scoped>
@import "https://cdn.jsdelivr.net/npm/summernote@0.8.18/dist/summernote-lite.min.css";
/* @import "https://cdnjs.cloudflare.com/ajax/libs/summernote/0.8.20/summernote.min.css"; */
</style>
