<template>
    <!-- <div ref="textarea"></div> -->
    <text-editor v-model="content"></text-editor>

    
    
</template>
<script>
// import summernote from "summernote";
import CustomEditor from "./summernote/CustomEditor.vue";
export default {
    components:{'text-editor':CustomEditor},

    data(){
        return {
            content:'',
            myValue: '',
            myOptions: ['op1', 'op2', 'op3']
        }
    },

};
</script>
<style scoped></style>
