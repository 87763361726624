<template>
    <div>
        <div class="card-body">
            <p class="header-title">
                {{ $t("Add Lesson for course") }}:
                <strong>{{ data.course_category?.title }}</strong>
            </p>

            <div v-for="(lesson, index) in form.lessons" :key="index">
                <p
                    class="d-flex align-items-center justify-content-between single-lesson"
                >
                    <a
                        data-bs-toggle="collapse"
                        :href="`#lesson${index + 1}`"
                        role="button"
                        aria-expanded="true"
                        :aria-controls="`lesson${index + 1}`"
                    >
                        <span v-if="lesson.title">{{ lesson.title }}</span>
                        <span v-else
                            >{{ $t("Lesson") }}
                            <!-- {{ index + 1 }} -->
                        </span>
                    </a>

                    <a
                        v-if="index != 0"
                        @click.prevent="removeLesson(lesson)"
                        class="text-danger text-bold"
                        href="#"
                        ><i class="ti-close"></i
                    ></a>
                </p>
                <div class="collapse show" :id="`lesson${index + 1}`">
                    <div class="row">
                        <span
                            v-if="isValidForQuiz"
                            class="d-flex align-items-center px-2"
                        >
                            <h4>
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    :id="`isLessonHasQuiz_${index}`"
                                    name="has_quiz"
                                    v-model="lesson.has_quiz"
                                    true-value="1"
                                    false-value="0"
                                />

                                <InputLabel
                                    label="Is only quizz system"
                                    class="px-2"
                                    :labelFor="`isLessonHasQuiz_${index}`"
                                />
                            </h4>
                        </span>
                        <InputGroup
                            class="col-xl-6 col-lg-6 p-2"
                            v-model="lesson.title"
                            :index="index"
                            label="Lesson title"
                            :name="`lessons.${index}.title`"
                            required
                            placeholder="Lesson title"
                            :errors="errors"
                            @focusout="generateSlug(lesson)"
                        />
                        <InputGroup
                            v-model="lesson.slug"
                            :index="index"
                            label="Lesson slug"
                            :name="`lessons.${index}.slug`"
                            required
                            placeholder="Lesson slug"
                            :errors="errors"
                            class="col-xl-6 col-lg-6 p-2"
                        />

                        <InputGroup
                            v-model="lesson.quiz_per_page"
                            v-if="lesson.has_quiz == 1"
                            label="Quiz per page"
                            :name="`lessons.${index}.quiz_per_page`"
                            type="number"
                            placeholder="Number of quiz show per page"
                            :errors="errors"
                            class="col-xl-6 col-lg-6 p-2"
                        />
                        <InputGroup
                            v-model="lesson.pass_marks"
                            v-if="lesson.has_quiz == 1"
                            label="Quiz pass marks in (%)"
                            :name="`lessons.${index}.pass_marks`"
                            type="number"
                            placeholder="Quiz pass marks"
                            :errors="errors"
                            class="col-xl-6 col-lg-6 p-2"
                        />
                    </div>

                    <!-- start topics -->
                    <topic-component
                        v-if="lesson.has_quiz != 1"
                        :lesson="lesson"
                        :key="index"
                        :settings="data?.settings"
                        :errors="errors"
                        :old_topics="lesson?.topics"
                        :isEditMode="isEditMode"
                        @topics="handleChangeTopics"
                        @loading="handleLoading"
                    />
                    <!-- topics end -->

                    <!-- start quiz component -->
                    <quiz-component
                        v-if="lesson.has_quiz == 1"
                        :isEditMode="isEditMode"
                        :parent_topic="lesson.topics[0]"
                        :old_quizzes="lesson?.quizzes"
                        :errors="errors"
                        @quizzes="handleChangeQuizzes"
                        :parent_name="`lessons.${index}`"
                    ></quiz-component>
                    <!-- end quiz component -->

                    <!-- lesson status -->
                    <div class="form-group col-xl-6 col-lg-6 p-2 mb-2">
                        <span class="d-flex align-items-center">
                            <input
                                type="checkbox"
                                :id="`lesson_status_change_${lesson.id}`"
                                class="mb-0 mr-2 pr-2 form-check-input"
                                v-model="lesson.status"
                                true-value="active"
                                false-value="inactive"
                                @change="changeStatus"
                            />
                            <label
                                class="px-2"
                                :for="`lesson_status_change_${lesson.id}`"
                                >{{ $t("Is lesson active") }}</label
                            >
                        </span>
                        <input-errors
                            :name="`lessons.${index}.status`"
                            :errors="errors"
                        ></input-errors>
                    </div>
                </div>
            </div>

            <!-- add new lesson -->
            <!-- <p>
            <a
                @click.prevent="addNewLesson"
                class="btn btn-sm btn-info"
                href="#"
                >Add lesson</a
            >
        </p> -->

            <!-- submit buttons -->
            <div class="form-group">
                <div class="d-flex">
                    <button
                        class="btn btn-primary waves-effect waves-lightml-2 ic_mr-2 d-flex align-items-center"
                        type="button"
                        @click.prevent="submitAction"
                        :disabled="isLoading"
                    >
                        <div
                            class="spinner-border text-info spinner-border-sm"
                            :class="isLoading ? 'd-block' : 'd-none'"
                            role="status"
                        >
                            <span class="sr-only">{{ $t("Loading") }}...</span>
                        </div>

                        <i
                            class="fa fa-save"
                            :class="isLoading ? 'd-none' : ''"
                        ></i>
                        <span class="course_submit_btn">{{
                            $t("Submit")
                        }}</span>
                    </button>
                    <a
                        class="btn btn-danger waves-effect"
                        :href="
                            route('lessons.index', {
                                course: data?.course_category?.slug,
                            })
                        "
                    >
                        <i class="fa fa-times"></i> {{ $t("Cancel") }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import inputGroup from "@/components/partials/inputGroup.vue";
import InputLabel from "@/components/partials/InputLabel.vue";
import { findBy, notifyMe } from "@/helpers";

import { v4 as uuidv4 } from "uuid";
import InputErrors from "../partials/InputErrors.vue";
import QuizComponent from "./topic/QuizComponent.vue";
import TopicComponent from "./topic/TopicComponent.vue";

export default {
    components: { inputGroup, TopicComponent, InputErrors },
    props: ["data", "old_lesson"],
    data() {
        return {
            errors: {},
            isLoading: true,
            isReady: false,
            dataEmptyLesson: {
                id: uuidv4(),
                title: "",
                has_quiz: false,
                quiz_per_page: 0,
                pass_marks: 0,
                status: "active",
                course_id: this?.data?.course_category?.id,
                slug: "",
                topics: [],
                quizzes: [],
            },
            form: {
                lessons: [],
                course_id: this?.data?.course_category?.id,
            },
        };
    },

    methods: {
        // add new empty lesson
        addNewLesson() {
            this.dataEmptyLesson.id = uuidv4();
            this.form.lessons.push({ ...this.dataEmptyLesson });
        },

        removeLesson(lesson) {
            this.form.lessons.splice(this.form.lessons.indexOf(lesson), 1);
        },

        // generate slug for title
        async generateSlug(lesson) {
            if (lesson.title.length > 0) {
                const data = {
                    title: lesson.title,
                };
                await axios
                    .post(route("lesson.slug.generate", data))
                    .then((res) => {
                        const { data, status, message } = res.data;
                        if (status == "success") {
                            lesson.slug = data;
                        }
                    });
            }
        },

        // action submit
        async submitAction() {
            this.isLoading = true;
            const data = this.form;
            const self = this;
            let actionUrl = route("lessons.store");
            let method = "post";

            if (this.isEditMode) {
                actionUrl = route("lessons.update", this.old_lesson?.id);
                method = "put";
            }
            try {
                await axios[method](actionUrl, data)
                    .then((res) => {
                        const { data, message, status } = res.data;
                        this.isLoading = false;
                        if (status == "success") {
                            notifyMe(message, "success");
                            const redirectUrl = route("lessons.index", {
                                course: this.data?.course_category?.slug,
                            });
                            window.location.href = redirectUrl;
                        }
                    })
                    .catch(function (error) {
                        const { data: allErrors = {} } = error?.response?.data;
                        self.errors = allErrors;
                        self.isLoading = false;
                    });
            } catch (error) {
                this.isLoading = false;
                // console.log('catche:', error);
            }
        },

        handleChangeTopics(topics) {
            const lessonIndex = findBy(
                this.form.lessons,
                topics.lesson_id,
                "id"
            );
            if (lessonIndex >= 0) {
                this.form.lessons[lessonIndex].topics = [...topics.data];
            }
        },

        handleChangeQuizzes(quizzes) {
            const lessonIndex = findBy(
                this.form.lessons,
                quizzes.topic_id,
                "id"
            );
            if (lessonIndex >= 0) {
                this.form.lessons[lessonIndex].quizzes = [...quizzes.data];
            } else {
                this.form.lessons[0].quizzes = [...quizzes.data];
            }
        },

        handleLoading(loading) {
            this.isLoading = loading;
        },
    },
    created() {
        // this.$nextTick(function () {
        this.isLoading = true;
        // })
    },

    mounted() {
        this.$nextTick(function () {
            this.isLoading = false;
        });
        if (!this.isEditMode) {
            this.addNewLesson();
        } else {
            this.form.lessons.push({ ...this.old_lesson });
        }
    },
    computed: {
        isEditMode: function () {
            if (this.old_lesson?.id) {
                return true;
            } else {
                return false;
            }
        },
        isValidForQuiz: function () {
            if (this.old_lesson?.topics?.length > 0) {
                return false;
            } else {
                return true;
            }
        },
    },
};
</script>
<style scoped>
.single-lesson {
    background: #edf4f0;
    padding: 0.6rem;
    border-radius: 5px;
}
.course_submit_btn {
    margin-left: 0.3rem;
}
</style>
