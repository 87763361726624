<template>
  <div>
    <div class="card-body">
      <div class="header-title d-flex justify-content-between">
        <h4> {{ $t("Module") }}:</h4>
        <div class="text-right">
          <h6>
            {{ $t("Sat Name") }}: {{ data?.practice?.sat?.title }} <br>
            {{ $t("Practice") }}: {{ data?.practice?.name }}
          </h6>
          <p></p>
        </div>
      </div>

      <div v-for="(module, index) in form.modules" :key="index">
        <div class="collapse show" :id="`module${index + 1}`">
          <div class="row">
            <InputGroup
              class="col-xl-6 col-lg-6 p-2"
              v-model="module.name"
              :index="index"
              label="Module name"
              :name="`modules.${index}.name`"
              required
              placeholder="Module name"
              :errors="errors"
              @focusout="generateSlug(module)"

            />
            <!--            <InputGroup
                          v-model="module.slug"
                          :index="index"
                          label="Module slug"
                          :name="`modules.${index}.slug`"
                          required
                          placeholder="Module slug"
                          :errors="errors"
                          class="col-xl-6 col-lg-6 p-2"
                        />-->


            <div class="form-group col-xl-6 col-lg-6 p-2">
              <label>{{ $t('Subject') }}
                <span class="error">*</span>
              </label>

              <v-select
                v-model="module.sat_subject_id"
                :reduce="(option) => option.value"
                :key="module.sat_subject_id"
                :options="subjects.map((item)=> {
                  return {
                     value: item.id,
                     label: item.subject.name
                  }
                })"
                @option:selected="onSubjectChange(index)"
                :placeholder="'Select a subject'"
              ></v-select>

              <input-errors :name="`modules.${index}.sat_subject_id`" :errors="errors"></input-errors>
            </div>

            <div class="form-group col-xl-12 col-lg-12 p-2"
                 v-if="data?.is_sat"
            >
              <label>{{ $t('Type') }}
                <span class="error">*</span>
              </label>

              <v-select
                v-model="module.type"
                :reduce="(option) => option.id"
                class="text-uppercase"
                :options="Object.entries(module_types).map(([key, value])=> {
                  return {
                     id: key,
                     label: value
                  }
                })"
                :placeholder="'Select a type'"
              ></v-select>

              <input-errors :name="`modules.${index}.type`" :errors="errors"></input-errors>
            </div>
          </div>


          <!-- start quiz component -->
          <quiz-component
            :isEditMode="isEditMode"
            :old_quizzes="module?.quizzes"
            :errors="errors"
            @quizzes="handleChangeQuizzes"
            :parent_name="`modules.${index}`"
          ></quiz-component>
          <!-- end quiz component -->

          <!-- module status -->
          <div class="form-group col-xl-6 col-lg-6 p-2 mb-2">
                        <span class="d-flex align-items-center">
                            <input
                              type="checkbox"
                              :id="`module_status_change_${module.id}`"
                              class="mb-0 mr-2 pr-2 form-check-input"
                              v-model="module.status"
                              true-value="active"
                              false-value="inactive"
                              @change="changeStatus"
                            />
                            <label
                              class="px-2"
                              :for="`module_status_change_${module.id}`"
                            >{{ $t("Is module active") }}</label
                            >
                        </span>
            <input-errors
              :name="`modules.${index}.status`"
              :errors="errors"
            ></input-errors>
          </div>
        </div>
      </div>

      <!-- submit buttons -->
      <div class="form-group">
        <div class="d-flex">
          <button
            class="btn btn-primary waves-effect waves-lightml-2 ic_mr-2 d-flex align-items-center"
            type="button"
            @click.prevent="submitAction"
            :disabled="isLoading"
          >
            <div
              class="spinner-border text-info spinner-border-sm"
              :class="isLoading ? 'd-block' : 'd-none'"
              role="status"
            >
              <span class="sr-only">{{ $t("Loading") }}...</span>
            </div>

            <i
              class="fa fa-save"
              :class="isLoading ? 'd-none' : ''"
            ></i>
            <span class="course_submit_btn">{{
                $t("Submit")
              }}</span>
          </button>
          <a
            class="btn btn-warning waves-effect"
            :href="
              route('practices.modules.index', form.practice_id)
            "
          >
            <i class="fa fa-times"></i> {{ $t("Cancel") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InputGroup from "@/components/partials/InputGroup.vue";
import {findBy, notifyMe} from "@/helpers";

import {v4 as uuidv4} from "uuid";
import InputErrors from "../partials/InputErrors.vue";
import TopicComponent from "../lesson/topic/TopicComponent.vue";
import vSelect from 'vue-select'

export default {
  components: {InputGroup, TopicComponent, InputErrors, vSelect},
  props: ["data", "old_module"],
  data() {
    return {
      errors: {},
      isLoading: true,
      isReady: false,
      subjects: this.data?.sat_subjects,
      module_types: this.data?.module_types,
      dataEmptyLesson: {
        id: uuidv4(),
        title: "",
        has_quiz: true,
        quiz_per_page: 0,
        pass_marks: 0,
        status: 'active',
        sat_id: this?.data?.practice?.sat_id,
        practice_id: this?.data?.practice?.id,
        sat_subject_id: "",
        slug: "",
        type: this?.data?.module_types?.normal,
        // topics: [],
        quizzes: [],
      },
      form: {
        modules: [],
        sat_id: this?.data?.practice?.sat_id,
        sat_subject_id: "",
        practice_id: this?.data?.practice?.id,
      },
    };
  },

  methods: {
    async onSubjectChange(index) {
      let self = this;
      self.module_types = {};

      let module = self.form.modules[index]

      await axios
        .get(route("module-types", {
          sat_subject_id: module.sat_subject_id,
          practice_id: self.form.practice_id,
          module_id: module.id,
        }))
        .then((res) => {
          console.log(res)
          const {data, status, message} = res.data;
          if (status == "success") {
            self.module_types = data
          }
        });
    },

    // add new empty module
    addNewModule() {
      this.dataEmptyLesson.id = uuidv4();
      this.form.modules.push({...this.dataEmptyLesson});
    },

    removeLesson(module) {
      this.form.modules.splice(this.form.modules.indexOf(module), 1);
    },

    // generate slug for title
    async generateSlug(module) {
      if (module.name.length > 0) {
        const data = {
          name: module.name,
        };
        await axios
          .post(route("modules.slug-generate", data))
          .then((res) => {
            const {data, status, message} = res.data;
            if (status == "success") {
              module.slug = data;
            }
          });
      }
    },

    // action submit
    async submitAction() {
      this.isLoading = true;
      const data = this.form;
      const self = this;
      let actionUrl = route("practices.modules.store", this.form.practice_id);
      let method = "post";

      if (this.isEditMode) {
        actionUrl = route("practices.modules.update", [this.form.practice_id, this.old_module?.id]);
        method = "put";
      }
      try {
        await axios[method](actionUrl, data)
          .then((res) => {
            const {data, message, status} = res.data;
            this.isLoading = false;
            if (status === "success") {
              notifyMe(message, "success");
              window.location.href = route("practices.modules.index", this.form.practice_id);
            }
          })
          .catch(function (error) {
            if (error.response.data.status === 'failed') {
              notifyMe(error.response.data.message, "error");
            }

            const {data: allErrors = {}} = error?.response?.data;
            self.errors = allErrors;
            self.isLoading = false;
          });
      } catch (error) {
        this.isLoading = false;
        // console.log('catche:', error);
      }
    },

    handleChangeQuizzes(quizzes) {
      const index = findBy(
        this.form.modules,
        quizzes.topic_id,
        "id"
      );
      if (index >= 0) {
        this.form.modules[index].quizzes = [...quizzes.data];
      } else {
        this.form.modules[0].quizzes = [...quizzes.data];
      }
    },

    handleLoading(loading) {
      this.isLoading = loading;
    },
  },
  created() {
    // this.$nextTick(function () {
    this.isLoading = true;
    // })
  },

  mounted() {
    this.$nextTick(function () {
      this.isLoading = false;
    });
    if (!this.isEditMode) {
      this.addNewModule();
    } else {
      setTimeout(()=>{
          this.form.modules.push({...this.old_module, sat_subject_id: +this.old_module.sat_subject_id});
          this.form = {...this.form}
      }, 1500)
    }
  },
  computed: {
    isEditMode: function () {
      if (this.old_module?.id) {
        return true;
      } else {
        return false;
      }
    },
    isValidForQuiz: function () {
      if (this.old_module?.topics?.length > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
<style scoped>
.single-module {
  background: #edf4f0;
  padding: 0.6rem;
  border-radius: 5px;
}

.course_submit_btn {
  margin-left: 0.3rem;
}
</style>
