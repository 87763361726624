<template>
    <div class="card-body">
        <p class="header-title">
            {{ $t("Add Quiz for course") }}:
            <strong>{{ form.course?.title }}</strong>
        </p>
        test
        <div class="row">
            <!-- <div class="form-group col-xl-6 col-lg-6 p-2">
                <label for="">{{ $t("Select Course") }}</label>
                <v-select v-model="form.course" :options="data.courses" label="title" ></v-select>
            </div> -->

            <InputGroup
                v-model="form.course.quiz_per_page"
                v-if="form.course.has_quiz == 1"
                label="Quiz per page"
                :name="`course.quiz_per_page`"
                type="number"
                placeholder="Number of quiz show per page"
                :errors="errors"
                class="col-xl-6 col-lg-6 p-2"
            />
            <InputGroup
                v-model="form.course.pass_marks"
                v-if="form.course.has_quiz == 1"
                label="Quiz pass marks in (%)"
                :name="`course.pass_marks`"
                type="number"
                placeholder="Quiz pass marks"
                :errors="errors"
                class="col-xl-6 col-lg-6 p-2"
            />

            <!-- <div class="form-group col-xl-4 col-lg-4 p-2">
                <span class="d-block align-items-center">
                    <InputLabel label="Is available quiz" labelFor="course_has_quiz" />
                    <span class="d-flex align-items-center px-2">
                        <input
                            id="course_has_quiz"
                            class="form-check-input"
                            type="checkbox"
                            name="has_quiz"
                            v-model="form.course.has_quiz"
                            true-value="1"
                            false-value="0"
                        />
                        <InputLabel label="Has Quiz " class="px-2" />
                    </span>
                </span>

                <input-errors
                    :errors="errors"
                    :name="`course.has_quiz`"
                ></input-errors>
            </div> -->
        </div>
        <div v-if="loading" class="spinner-loader">
            <div class="spinner-border text-info" role="status">
                <span class="visually-hidden">{{ $t('Loading') }}...</span>
            </div>
        </div>


        <!-- start quiz -->
        <quiz-component
            v-if="isMountQuiz"
            :isEditMode="isEditMode"
            :parent_topic="form.course"
            :old_quizzes="form.course?.quizzes"
            :errors="errors"
            @quizzes="handleChangeQuizzes"
            parent_name="course"
            :is_permit_to_add_new_quiz="isEditMode ? false : true"
        ></quiz-component>
        <!-- quiz end -->

         <!-- submit buttons -->
         <div class="form-group">
            <div class="d-flex">
                <button
                    class="btn btn-primary waves-effect waves-lightml-2 ic_mr-2 d-flex align-items-center"
                    type="button"
                    @click.prevent="submitAction"
                    :disabled="isLoading"
                >
                    <div class="spinner-border text-info spinner-border-sm" :class="isLoading ? 'd-block' : 'd-none'" role="status">
                        <span class="sr-only">{{ $t('Loading') }}...</span>
                    </div>

                    <i class="fa fa-save" :class="isLoading ? 'd-none' : ''"></i>
                    <span class="course_submit_btn">{{ $t("Submit") }}</span>
                </button>
                <a
                    class="btn btn-danger waves-effect"
                    :href="route('course-quizs.index', {
                            course: old_course?.slug,
                        })"
                >
                    <i class="fa fa-times"></i> {{ $t("Cancel") }}
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import QuizComponent from "@/components/lesson/topic/QuizComponent.vue";
import InputErrors from "@/components/partials/InputErrors.vue";
import InputLabel from "@/components/partials/InputLabel.vue";
import inputGroup from "@/components/partials/inputGroup.vue";
import { notifyMe } from "@/helpers";
import vSelect from 'vue-select';
export default {
    props: ["data", 'old_course'],
    components: { vSelect, QuizComponent, inputGroup, InputLabel, InputErrors },
    data() {
        return {
            errors: {},
            isLoading:false,
            form: {
                course:{
                    pass_marks:'',
                    has_quiz: 1,
                    quiz_per_page:'',

                }
            },
        };
    },
    methods:{
        handleChangeQuizzes(quizzes){
            this.form.course.quizzes = [...quizzes.data];
        },

        // action submit
        async submitAction() {
                  this.isLoading = true;
            const data           = this.form;
            const self           = this;
            let   actionUrl      = route("course-quizs.store");
            let   method         = "post";

            if (this.isEditMode) {
                actionUrl = route("course-quizs.update", this.old_course?.id);
                method = "put";
            }

            await axios[method](actionUrl, data)
                .then((res) => {
                    const { data, message, status } = res.data;

                    if (status == "success") {
                        notifyMe(message, "success");
                        const redirectUrl = route("course-quizs.index", {
                            course: this.old_course?.slug,
                        });
                        window.location.href = redirectUrl;
                        this.isLoading = false;
                    }
                })
                .catch(function (error) {
                    const { data: allErrors = {} } = error?.response?.data;
                    self.errors = allErrors;
                    self.isLoading = false;
                    // console.log('errors:', error);
                });
        },

    },

    mounted() {
        this.form.course = { ...this?.old_course }
    },

    computed: {
        isEditMode: function () {
            if (this.data.type && this.data?.type == 'edit') {
                return true;
            } else {
                return false;
            }
        },
        isMountQuiz: function () {
            if (this.isEditMode) {
                if (this.form.course?.quizzes?.length) {
                    return true;
                }
            } else {
                return true;
            }
        },


    },
    watch:{
        'form.course': function (newVal, oldVal){
            this.form.course.has_quiz = 1;
        },
    }
}
</script>

<style scoped>
.spinner-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}
</style>
