<template>
    <div class="d-flex justify-content-between align-items-end g-3">
        <input
            type="file"
            ref="fileInput"
            @change="handleFileSelected"
            :name="name"
            class="form-control"
            :placeholder="$t('Video file')"
            :accept="accepted"
        />
        <button
            @click="startUpload"
            v-if="hasFile"
            style="flex: 0 0 auto; height: 34.33px"
            class="ms-1 btn btn-success btn-sm d-flex align-items-center position-relative overflow-hidden"
            :disabled="isUploading"
            type="button"
        >
            <span v-if="!isUploading">
                <i class="fa fa-upload"></i>
                <span>Upload </span>
            </span>

            <span
                class="spinner-border text-info spinner-border-sm"
                role="status"
                :class="isUploading ? 'd-block' : 'd-none'"
            >
                <span>Uploading...</span>
            </span>
        </button>

        <button
            style="flex: 0 0 auto; height: 34.33px"
            v-if="hasFile && fileData"
            class="ms-1 btn btn-danger btn-sm d-flex align-items-center position-relative overflow-hidden"
            @click="deleteFile(fileData)"
            :disabled="isUploading"
            type="button"
        >
            <span v-if="!isUploading">
                <span>{{ $t("Delete") }}</span>
                <i class="ms-1 fas fa-times-circle"></i>
            </span>
            <span
                class="spinner-border text-info spinner-border-sm"
                :class="isUploading ? 'd-block' : 'd-none'"
                role="status"
            >
                <span>{{ $t("Deleting") }}...</span>
            </span>
        </button>
    </div>
</template>

<script>
import resumable from "resumablejs";
import { notifyMe } from "@/helpers";

export default {
    props: {
        value: {
            type: [Array, Object],
            default: "",
        }, // v-model value
        uploadDir: {
            type: String,
            default: "upload",
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        isButtonDisable: {
            type: Boolean,
            default: false,
        },

        name: String,
        uploadUrl: {
            type: String,
            default: "/chunk-upload",
        },
        deleteUrl: {
            type: String,
            default: route("remove.file"),
        },
        method: {
            type: String,
            default: "POST",
        },
        acceptedFiles: {
            type: Array,
            default: [
                "mp4",
                "x-m4v",
                "mkv",
                "avi",
                "mov",
                "mpeg",
                "mpg",
                "mpeg4",
                "webm",
            ],
        },
    },
    data() {
        return {
            uploader: null,
            // isLoading: false,
            // isButtonDisable: false,
            hasFile: false,
            isUploading: false,
            uploadError: null,
            uploadingProgress: 0,
            fileData: this.value, // after uploading response will be stored here
            // accepted: this.acceptedFiles.join(','),  //join ex: ".mp4, .mkv"
            csrfToken: document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content"),
        };
    },
    methods: {
        handleFileSelected(event) {
            const file = event.target.files[0];
            if (!file) {
                return;
            }

            this.uploader = new resumable({
                // Resumable.js configuration options
                target: this.uploadUrl, // Replace with your server-side upload URL
                testMethod: this.method,
                uploadMethod: this.method,
                throttleProgressCallbacks: 1,
                maxChunkRetries: 3,
                testChunks: false,
                chunkSize: 10 * 1024 * 1024, // 1MB chunk size
                simultaneousUploads: 3, // Upload 3 chunks concurrently
                fileType: this.acceptedFiles,
                maxFiles: 1,
                query: {
                    _token: this.csrfToken,
                    'upload_dir': this.uploadDir
                },
            });

            this.uploader.addFile(file);
            // this.isUploading = true;
            this.hasFile = true;
            this.uploadError = null;
        },
        startUpload() {
            if (!this.uploader) {
                return;
            }

            this.uploader.on("fileSuccess", (file, message) => {
                // Handle successful upload on the client-side (optional)
                // console.log("File uploaded successfully:", file);
                // console.log("Server response:", message);
                const { status, data } = JSON.parse(message);
                this.fileData = data;
                this.isUploading = false;
                // this.hasFile = false;
                this.$emit("update:model-value", data.file);
                this.$emit("onUploaded", data, this.isUploading);
                notifyMe("Upload success", "info");
                this.$emit("loadingStatus", this.isUploading);
            });

            this.uploader.on("fileProgress", (file) => {
                this.uploadingProgress = Math.floor(
                    (file.progress() * 100) / file.size
                );
            });
            this.uploader.on("fileError", (file, error) => {
                this.isUploading = false;
                this.uploadError = `Upload failed: ${error.message}`;
                this.fileData = null;
                this.$emit("loadingStatus", this.isUploading);
            });
            this.uploader.on("uploadStart", () => {
                this.isUploading = true;
                this.uploadError = null;
                this.fileData = null;
                this.$emit("loadingStatus", this.isUploading);
            });

            this.uploader.upload();
        },

        deleteFile() {
            if (!this.uploader) {
                return;
            }
            this.isUploading = true;
            this.$emit("loadingStatus", this.isUploading);

            // Perform deletion logic here
            // For example:
            this.uploader.files.forEach((file) => {
                file.cancel();
            });

            // this.$emit("deleted"); // Emitting event for file deletion
            const data = new FormData();
            data.append("path", this.fileData.file);
            data.append("file_url", this.fileData.file_url);
            axios
                .post(this.deleteUrl, data)
                .then((res) => {
                    // Reset component state
                    this.uploadingProgress = 0;
                    this.uploadError = null;
                    this.fileData = null;
                    this.hasFile = false;
                    this.$emit("update:model-value", null);
                    this.isUploading = false;
                    this.$emit("loadingStatus", this.isUploading);
                    notifyMe("File deleted successfully", "info");
                })
                .catch(function (error) {
                    // notifyMe("File deleted failed", "error");
                    notifyMe("File deleted failed", "warning");
                    this.isUploading = false;
                    this.uploadingProgress = 0;
                    this.uploadError = null;
                    this.$emit("loadingStatus", this.isUploading);
                });
        },
    },

    computed: {
        accepted() {
            // if this.acceptedFiles is an array and matches the file type in the array return html accepted files type
            if (Array.isArray(this.acceptedFiles)) {
                return this.acceptedFiles
                    .map((fileType) => {
                        return `.${fileType}`;
                    })
                    .join(", ");
            } else {
                return this.acceptedFiles;
            }
        },
    },
};
</script>
