<template>
    <div class="card-body">
        <p class="header-title">
            {{ $t("Add Quiz for ged exam") }}:
            <strong>{{ form.ged_exam?.exam_title }}</strong>
        </p>
        <div class="row">
            <!-- <div class="form-group col-xl-6 col-lg-6 p-2">
                <label for="">{{ $t("Select Course") }}</label>
                <v-select v-model="form.ged_exam" :options="data.gedExams" label="exam_title" ></v-select>
            </div> -->

            <!-- <div class="form-group col-xl-6 col-lg-6"> -->
                <div class="row d-none">
                    <InputGroup
                        v-model="form.ged_exam.quiz_per_page"
                        label="Quiz per page"
                        :name="`course.quiz_per_page`"
                        type="number"
                        placeholder="Number of quiz show per page"
                        :errors="errors"
                        class="col-xl-6 col-lg-6 p-2"
                    />
                    <InputGroup
                        v-model="form.ged_exam.pass_marks"
                        label="Quiz pass marks in (%)"
                        :name="`course.pass_marks`"
                        type="number"
                        placeholder="Quiz pass marks"
                        :errors="errors"
                        class="col-xl-6 col-lg-6 p-2"
                    />
                </div>

            <!-- </div> -->
        </div>




        <!-- start quiz -->
        <quiz-component
            v-if="isMountQuiz"
            :isEditMode="isEditMode"
            :parent_topic="form.ged_exam"
            :old_quizzes="form.ged_exam?.quizzes"
            :errors="errors"
            @quizzes="handleChangeQuizzes"
            parent_name="ged_exam"
            :is_permit_to_add_new_quiz="isEditMode ? false : true"
        >
        </quiz-component>
        <!-- quiz end -->

         <!-- submit buttons -->
         <div class="form-group">
            <div class="d-flex">
                <button
                    class="btn btn-primary waves-effect waves-lightml-2 ic_mr-2 d-flex align-items-center"
                    type="button"
                    @click.prevent="submitAction"
                    :disabled="isLoading"
                >
                    <div class="spinner-border text-info spinner-border-sm" :class="isLoading ? 'd-block' : 'd-none'" role="status">
                        <span class="sr-only">{{ $t('Loading') }}...</span>
                    </div>

                    <i class="fa fa-save" :class="isLoading ? 'd-none' : ''"></i>
                    <span class="course_submit_btn">{{ $t("Submit") }}</span>
                </button>
                <a
                    class="btn btn-danger waves-effect"
                    :href="route('ged-exam-quizes.index', {
                            exam: old_ged_exam?.slug,
                        })"
                >
                    <i class="fa fa-times"></i> {{ $t("Cancel") }}
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import QuizComponent from "@/components/ged_exam_quiz/GedQuizComponent.vue";
import InputErrors from "@/components/partials/InputErrors.vue";
import inputGroup from "@/components/partials/inputGroup.vue";
import InputLabel from "@/components/partials/InputLabel.vue";
import { notifyMe } from "@/helpers";
import vSelect from 'vue-select';
export default {
    props: ["data", 'old_ged_exam'],
    components: { vSelect, QuizComponent, inputGroup, InputLabel, InputErrors },
    data() {
        return {
            errors: {},
            isLoading:false,
            form: {
                ged_exam:{
                    id           : this.old_ged_exam.id,
                    pass_marks   : this.old_ged_exam.pass_marks,
                    has_quiz     : 1,
                    quiz_per_page: this.old_ged_exam.quiz_per_page,
                    quizzes      : []
                }
            },
        };
    },
    methods:{
        // addNew
        handleChangeQuizzes(quizzes){

            this.form.ged_exam.quizzes = [...quizzes.data];
        },

        // action submit
        async submitAction() {
                  this.isLoading = true;
            const data           = this.form;
            const self           = this;
            let   actionUrl      = route("ged-exam-quizes.store");
            let   method         = "post";
            if (this.isEditMode) {
                actionUrl = route("ged-exam-quizes.update", this.old_ged_exam?.id);
                method = "put";
            }
            let _this = this;
            await axios[method](actionUrl, data)
                .then((res) => {
                    const { data, message, status } = res.data;
                    _this.isLoading = false;
                    if (status == "success") {
                        notifyMe(message, "success");
                        const redirectUrl = route("ged-exam-quizes.index", {
                            exam: this.old_ged_exam?.slug,
                        });
                        _this.isLoading = true;
                        window.location.href = redirectUrl;
                    }
                })
                .catch(function (error) {
                    const { data: allErrors = {} } = error?.response?.data;
                    self.errors = allErrors;
                    _this.isLoading = false;
                });
        },

    },

    mounted() {
        this.form.ged_exam = { ...this?.old_ged_exam };
    },

    computed: {

        isEditMode: function () {

            if (this.data.type && this.data?.type == 'edit') {
                return true;
            } else {
                return false;
            }
        },

        isMountQuiz: function () {
            if (this.isEditMode) {
                if (this.form.ged_exam?.quizzes?.length) {
                    return true;
                }
                return false;
            } else {
                return true;
            }
        },
    },
}
</script>

<style scoped>
</style>
