<template>
    <div class="mt-4">
        <p class="header-title">
            {{ $t("Add Topics for") }}
            <strong>{{ lesson?.title }}</strong>
        </p>

        <div
            class="topic-area mb-2"
            v-for="(topic, index) in topics"
            :key="index"
        >
            <p
                class="d-flex align-items-center justify-content-between single-topic"
            >
                <a
                    data-bs-toggle="collapse"
                    :href="`#topic${index + 1}`"
                    role="button"
                    aria-expanded="true"
                    :aria-controls="`topic${index + 1}`"
                >
                    <span v-if="topic.title">{{ topic.title }}</span>
                    <span v-else
                        >{{ $t("Topic") }}
                        {{ index + 1 }}
                    </span>
                </a>

                <a
                    v-if="index != 0"
                    @click.prevent="removeTopic(topic)"
                    class="text-danger text-bold"
                    href="#"
                    ><i class="ti-close"></i
                ></a>
            </p>
            <div
                class="collapse topic_container show"
                :id="`topic${index + 1}`"
            >
                <div class="row">
                    <InputGroup
                        class="col-xl-6 col-lg-6 p-2"
                        v-model="topic.topic_title"
                        label="Topic title"
                        :name="`lessons.${key}.topics.${index}.topic_title`"
                        required
                        placeholder="Topic title"
                        :errors="errors"
                        @focusout="generateSlug(topic)"
                    />
                    <InputGroup
                        v-model="topic.slug"
                        label="Topic slug"
                        :name="`lessons.${key}.topics.${index}.slug`"
                        required
                        placeholder="Topic slug"
                        :errors="errors"
                        class="col-xl-6 col-lg-6 p-2"
                    />

                    <div class="form-group col-xl-6 col-lg-6 p-2">
                        <div
                            class="d-flex flex-wrap justify-content-between align-items-center g-3"
                        >
                            <div class="w-100">
                                <!-- <div v-if="uploadProgress !== null">
                                    Uploading: {{ uploadProgress }}%
                                    <progress
                                        :value="uploadProgress"
                                        max="100"
                                    ></progress>
                                </div> -->
                                <span class="d-flex align-items-center">
                                    <InputLabel label="Video url" required />
                                    <span
                                        class="d-flex align-items-center px-2"
                                    >
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            :id="`isExternal_${index}`"
                                            name="is_external_video_url"
                                            v-model="
                                                topic.is_external_video_url
                                            "
                                            true-value="1"
                                            false-value="0"
                                        />
                                        <InputLabel
                                            label="is external url"
                                            class="px-2"
                                            :labelFor="`isExternal_${index}`"
                                        />
                                    </span>
                                    <input-errors
                                        :errors="errors"
                                        :name="`lessons.${key}.topics.${index}.is_external_video_url`"
                                    ></input-errors>
                                </span>
                                <div
                                    class="w-100 mt-2"
                                    v-if="topic.is_external_video_url == 1"
                                >
                                    <input-text
                                        type="url"
                                        name="video_url"
                                        v-model="topic.video_url"
                                        :placeholder="$t('Video Url')"
                                        :errors="errors"
                                    />
                                </div>
                            </div>
                        </div>

                        
                        <div
                            class="d-flex justify-content-between align-items-end g-3"
                            v-if="topic.is_external_video_url != 1"
                        >
                            <ResumableUploader
                                v-model="topic.video_url"
                                :isLoading="isLoading"
                                :isButtonDisable="isButtonDisable"
                                name="video_url"
                                uploadDir="topics"
                                uploadUrl="/chunk-upload"
                                :deleteUrl="route('remove.file')"
                                @loadingStatus="loadingStatus"
                                @onUploaded="resumableUploadHandler"
                            />
                            <!-- @deleted="handleDeleteFromServer" -->
                            <!-- <input
                                type="file"
                                name="video_url"
                                class="form-control"
                                accept="video/mp4,video/x-m4v, video/mkv,video/*"
                                :placeholder="$t('Video file')"
                                required
                                @change="
                                    handleStoreFileTempState(
                                        $event,
                                        'video_url'
                                    )
                                "
                            /> -->

                            <!-- <button
                                style="flex: 0 0 auto; height: 34.33px"
                                v-if="tempFile.video_url"
                                class="ms-1 btn btn-success btn-sm d-flex align-items-center position-relative overflow-hidden"
                                @click="
                                    handleUploadToServer(
                                        $event,
                                        topic,
                                        'video_url'
                                    )
                                "
                                :disabled="
                                    isButtonDisable || !tempFile.video_url
                                "
                                type="button"
                            >
                                <span v-if="!isButtonDisable && !isLoading">
                                    <i class="fa fa-upload"></i>
                                    <span>Upload </span>
                                </span>
                                <span
                                    class="spinner-border text-info spinner-border-sm"
                                    :class="
                                        isButtonDisable ? 'd-block' : 'd-none'
                                    "
                                    role="status"
                                >
                                    <span>{{ $t("Uploading") }}...</span>
                                </span>
                            </button> -->

                            <!-- <button
                                style="flex: 0 0 auto; height: 34.33px"
                                v-if="!tempFile.video_url"
                                class="ms-1 btn btn-danger btn-sm d-flex align-items-center position-relative overflow-hidden"
                                @click="deleteUploadedFile(topic, 'video_url')"
                                :disabled="isLoading"
                                type="button"
                            >
                                <span v-if="!isButtonDisable && !isLoading">
                                    <span>{{ $t("Delete") }}</span>
                                    <i class="ms-1 fas fa-times-circle"></i>
                                </span>
                                <span
                                    class="spinner-border text-info spinner-border-sm"
                                    :class="
                                        isButtonDisable ? 'd-block' : 'd-none'
                                    "
                                    role="status"
                                >
                                    <span>{{ $t("Deleting") }}...</span>
                                </span>
                            </button>  -->
                        </div>

                        <input-errors
                            :errors="errors"
                            :name="`lessons.${key}.topics.${index}.video_url`"
                        ></input-errors>
                    </div>

                    <InputGroup
                        v-model="topic.video_duration"
                        label="Video duration in (minute)"
                        :name="`lessons.${key}.topics.${index}.video_duration`"
                        type="number"
                        placeholder="Video duration"
                        :errors="errors"
                        class="col-xl-6 col-lg-6 p-2"
                    />

                    <div class="form-group col-xl-6 col-lg-6 p-2">
                        <div class="row">
                            <div class="col-md-12">
                                <label
                                    >{{ $t("Video thumbnail") }}
                                    <!-- <span class="error">*</span> -->
                                </label>
                                <input
                                    type="file"
                                    name="thumbnail"
                                    accept="image/*"
                                    class="form-control"
                                    :placeholder="$t('Video thumbnail')"
                                    required
                                    @change="
                                        uploadFileHandler(
                                            $event,
                                            topic,
                                            'thumbnail'
                                        )
                                    "
                                />
                            </div>
                            <div class="mt-1">
                                <img
                                    style="width: unset"
                                    width="50"
                                    height="50"
                                    class="video_thumbnail rounded d-block me-auto"
                                    :src="topic.thumbnail_url"
                                />
                            </div>
                        </div>

                        <input-errors
                            :errors="errors"
                            :name="`lessons.${key}.topics.${index}.thumbnail`"
                        ></input-errors>
                    </div>

                    <div class="form-group col-xl-6 col-lg-6 p-2">
                        <label>{{ $t("Upload file") }} </label>
                        <input
                            type="file"
                            name="file"
                            class="form-control"
                            :placeholder="$t('Essential file')"
                            required
                            @change="uploadFileHandler($event, topic, 'file')"
                        />

                        <input-errors
                            :errors="errors"
                            :name="`lessons.${key}.topics.${index}.file`"
                        ></input-errors>
                    </div>

                    <div class="form-group col-xl-12 col-lg-12 p-2">
                        <span class="d-block align-items-center">
                            <InputLabel label="Is available quiz" />
                            <span class="d-flex align-items-center px-2">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    :id="`isTopicHasQuiz_${index}`"
                                    name="has_quiz"
                                    v-model="topic.has_quiz"
                                    true-value="1"
                                    false-value="0"
                                />
                                <InputLabel
                                    label="Has Quiz "
                                    :labelFor="`isTopicHasQuiz_${index}`"
                                    class="px-2"
                                />
                            </span>
                        </span>

                        <input-errors
                            :errors="errors"
                            :name="`lessons.${key}.topics.${index}.has_quiz`"
                        ></input-errors>
                    </div>

                    <InputGroup
                        v-model="topic.quiz_per_page"
                        v-if="topic.has_quiz == 1"
                        label="Quiz per page"
                        :name="`lessons.${key}.topics.${index}.quiz_per_page`"
                        type="number"
                        placeholder="Number of quiz show per page"
                        :errors="errors"
                        class="col-xl-6 col-lg-6 p-2"
                    />
                    <InputGroup
                        v-model="topic.pass_marks"
                        v-if="topic.has_quiz == 1"
                        label="Quiz pass marks in (%)"
                        :name="`lessons.${key}.topics.${index}.pass_marks`"
                        type="number"
                        placeholder="Quiz pass marks"
                        :errors="errors"
                        class="col-xl-6 col-lg-6 p-2"
                    />

                    <div class="form-group col-xl-12 col-lg-12 p-2">
                        <label>{{ $t("Sort Description") }} </label>

                        <textarea
                            v-model="topic.sort_desc"
                            name="sort_desc"
                            class="form-control"
                        ></textarea>
                        <input-errors
                            :errors="errors"
                            :name="`lessons.${key}.topics.${index}.sort_desc`"
                        ></input-errors>
                    </div>
                </div>
                <!-- {{ updateRenderCount(index + 1) }} -->
                <!-- start quiz -->
                <quiz-component
                    v-if="topic.has_quiz == 1"
                    :isEditMode="isEditMode"
                    :parent_topic="topic"
                    :old_quizzes="topic?.quizzes"
                    :errors="errors"
                    @quizzes="handleChangeQuizzes"
                    :parent_name="`lessons.${key}.topics.${index}`"
                ></quiz-component>
                <!-- quiz end -->
            </div>
        </div>

        <!-- add new lesson -->
        <p>
            <button
                @click.prevent="addNewTopic"
                class="btn btn-sm btn-info mt-2"
                :disabled="isButtonDisable || isLoading"
            >
                {{ $t("Add Topic") }}
            </button>
        </p>
    </div>
</template>
<script>
import InputLabel from "@/components/partials/InputLabel.vue";
import InputText from "@/components/partials/InputText.vue";
import inputGroup from "@/components/partials/inputGroup.vue";
import { findBy, notifyMe } from "@/helpers";
import { v4 as uuidv4 } from "uuid";
import InputErrors from "../../partials/InputErrors.vue";
import QuizComponent from "./QuizComponent.vue";
// const loaderSvg = "/images/default/loader.svg";


// optionally import default styles
import ResumableUploader from "../../resumable/ResumableUploader.vue";

export default {
    // name: "TopicComponent",
    components: {
        inputGroup,
        InputLabel,
        InputText,
        QuizComponent,
        InputErrors,
        ResumableUploader,
    },
    props: {
        isEditMode: {
            type: [Boolean, Number],
            default: false,
        },
        lesson: {
            type: Object,
            default: {},
        },
        old_topics: {
            type: [Array, Object],
            default: [],
        },
        key: {
            type: Number,
            default: 0,
        },
        errors: {
            default: {},
        },
        settings: {
            default: {},
        },
    },

    data() {
        return {
            csrfToken: document.head.querySelector("[name=csrf-token]").content,

            isUploading: false,
            isLoading: false,
            isButtonDisable: false,
            tempFile: {},
            dataLength: 0,
            renderNumber: 0,
            dataEmptyTopic: {
                id: uuidv4(),
                topic_title: "",
                slug: "",
                lesson_id: this.lesson.id,
                video_url: null,
                video_duration: 0,
                thumbnail: "",
                is_external_video_url: 1,
                file: "",
                has_quiz: false,
                quiz_per_page: this.settings?.quiz_perpage,
                sort_desc: "",
                pass_marks: this.settings?.pass_marks,
                quizzes: [],
            },
            topics: [],
        };
    },

    methods: {
        // add new empty topic
        addNewTopic() {
            this.dataEmptyTopic.id = uuidv4();
            this.topics.push({ ...this.dataEmptyTopic });
        },

        removeTopic(topic) {
            this.$swal({
                title: `${this.$t("Are you sure")}?`,
                text: `${this.$t("You won't be able to revert this")}!`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `${this.$t("Yes, delete it")}!`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.topics.splice(this.topics.indexOf(topic), 1);
                    // action for delete
                    axios
                        .post(route("topics.delete", topic.id))
                        .then((res) => {
                            const { status } = res.data;
                            if (status == "success") {
                                this.$swal(
                                    `${this.$t("Deleted")}!`,
                                    `${this.$t("Item has been deleted")}.`,
                                    "success"
                                );
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            });
        },

        uploadFileHandler(e, targetObj, propertyName) {
            let propertyUrl = "";
            if (propertyName != "video_url") {
                propertyUrl = propertyName + "_url";
            }
            var data = new FormData();
            const file = e.target.files[0];
            data.append("file", file);
            data.append("old_file", targetObj[propertyName]);

            // Simulate progress (adjust timing based on your expected S3 upload speed)
            let startTime = Date.now();
            let simulatedProgress = 0;
            let simulateUpload = setInterval(() => {
                let currentTime = Date.now();
                simulatedProgress = Math.min(
                    ((currentTime - startTime) / 5000) * 100,
                    100
                ); // Adjust time accordingly
                this.uploadProgress = Math.round(simulatedProgress);
                if (simulatedProgress >= 100) {
                    clearInterval(simulateUpload);
                }
            }, 100);
            axios
                .post(route("topics.file.upload"), data, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },

                    // onUploadProgress: (progressEvent) => {
                    //     this.uploadProgress = Math.round(
                    //         (progressEvent.loaded * 100) / progressEvent.total
                    //     );
                    // },
                })
                .then((res) => {
                    const { file_url, file } = res.data?.data;

                    targetObj[propertyName] = file;
                    if (propertyName != "video_url") {
                        targetObj[propertyUrl] = file_url;
                    }
                    this.uploadProgress = null;
                    clearInterval(simulateUpload);
                    // targetObj[propertyUrl] = file_url;
                });
        },

        handleStoreFileTempState(event, propertyName) {
            const self = this;
            self.isButtonDisable = true;
            self.tempFile[propertyName] = event.target.files[0];
            self.isButtonDisable = false;
        },
        // video file upload handler
        handleUploadToServer(event, targetObj, propertyName) {
            const self = this;
            self.isButtonDisable = true;
            self.isLoading = true;
            const propertyUrl = propertyName + "_url";
            var data = new FormData();
            const file = this.tempFile[propertyName];
            data.append("file", file);
            data.append("old_file", targetObj[propertyName]);

            axios
                .post(route("topics.file.upload"), data, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },

                    // onUploadProgress: (progressEvent) => {
                    //     this.uploadProgress = Math.round(
                    //         (progressEvent.loaded * 100) / progressEvent.total
                    //     );
                    // },
                })
                .then((res) => {
                    const { file_url, file } = res.data?.data;

                    targetObj[propertyName] = file;
                    targetObj[propertyUrl] = file_url;
                    self.isButtonDisable = false;
                    self.isLoading = false;
                    notifyMe("Upload success", "info");
                    // remove tempFile data
                    this.tempFile[propertyName] = false;
                    this.uploadProgress = null;
                    clearInterval(simulateUpload);
                })
                .catch(function (error) {
                    self.isButtonDisable = false;
                    self.isLoading = false;
                    notifyMe("Upload failed! please try again", "error");
                    this.uploadProgress = null;
                    clearInterval(simulateUpload);
                });
        },

        // delete uploaded file
        deleteUploadedFile(targetObj, propertyName) {
            this.isButtonDisable = true;
            this.isLoading = true;

            const filePath = targetObj[propertyName];
            const data = new FormData();
            data.append("path", filePath);
            route("remove.file");
            axios
                .post(route("remove.file"), data)
                .then((res) => {
                    targetObj[propertyName] = "";
                    notifyMe("File deleted successfully", "info");
                    this.isButtonDisable = false;
                    this.isLoading = false;
                })
                .catch(function (error) {
                    notifyMe("File deleted failed", "error");
                    this.isButtonDisable = false;
                    this.isLoading = false;
                });
        },

        // generate slug for title
        async generateSlug(topic) {
            if (topic.topic_title.length > 0) {
                const data = {
                    title: topic.topic_title,
                };
                await axios
                    .post(route("topics.slug.generate", data))
                    .then((res) => {
                        const { data, status, message } = res.data;
                        if (status == "success") {
                            topic.slug = data;
                        }
                    });
            }
        },

        handleChangeQuizzes(quizzes) {
            const topicIndex = findBy(this.topics, quizzes.topic_id, "id");
            if (topicIndex >= 0) {
                this.topics[topicIndex].quizzes = [...quizzes.data];
            }
        },
        updateRenderCount(index) {
            this.renderNumber = index;
        },



        resumableUploadHandler(file) {
            // console.log('resumableUploadHandler', file);
        },
        loadingStatus(isLoading) {
            this.isButtonDisable = isLoading;
            this.isLoading = isLoading;
        },
    },

    watch: {
        // loading state
        isLoading: {
            immediate: true,
            handler: function (newVal) {
                const isDisabled = this.isButtonDisable ? true : false;
                const loading = newVal || isDisabled ? true : false;
            },
        },

        topics: {
            immediate: true,
            handler: function (newVal) {
                if (this.topics.length > 0) {
                    const data = {
                        lesson_id: this.lesson.id,
                        data: this.topics,
                    };
                    this.$emit("topics", data);
                }
            },
            deep: true,
        },
    },

    mounted() {
        if (!this.isEditMode) {
            this.addNewTopic();
        } else {
            this.dataLength = this.old_topics.length;
            this.topics = [...this.old_topics];
        }
    },

    computed: {
        // isReady: function () {
        //     if (this.dataLength == this.renderNumber) {
        //         this.isLoading = false
        //         return true;
        //     } else {
        //         return false;
        //     }
        // },
    },
};
</script>
