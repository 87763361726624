<template>
  <div class="card-body">
      <p class="header-title">
          {{ $t("Add Quiz for") }}
          <strong>{{ parents.title || parents.topic_title }}</strong>
      </p>
      <div class="mt-2">

          <!-- quiz area -->
          <div class="quiz-area mb-2" v-for="(quiz, quizIndex) in quizzes" :key="quiz.id">
              <p class="d-flex align-items-center justify-content-between single-quiz">
                  <a data-bs-toggle="collapse" :href="`#quiz${quizIndex + 1}`" role="button" aria-expanded="true" :aria-controls="`quiz${quizIndex + 1}`">
                      <!-- <span v-if="quiz.title">{{ quizIndex + 1 +". "+ quiz.title }}</span> -->
                      <span v-if="quiz.title">{{ quizIndex + 1 +". " }} <span v-html="quiz.title"></span> </span>
                  </a>
                  <!-- topic id -->
                  <div class="d-flex">
                      <a v-if="quizIndex != 0" @click.prevent="confirmRemoveQuiz(quiz)" class="text-danger text-bold" href="#"><i class="ti-close"></i></a>
                  </div>
              </p>
              <div class="collapse quiz_container show" :id="`quiz${quizIndex + 1}`">
                  <div class="row">
                      <!-- <InputGroup class="col-xl-8 col-lg-8" v-model="quiz.title" label="Quiz title" :name="`quizzes.${quizIndex}.title`" required placeholder="Quiz title" :errors="errors" @focusout="generateSlug(quiz)" /> -->

                      <div class="col-xl-8 col-lg-8">
                          <label>{{ $t("Quiz title") }}</label>
                          <text-editor v-model="quiz.title" :config="toolbarOptions" ></text-editor>
                          <input-errors :errors="errors" :name="`quizzes.${quizIndex}.title`" />
                      </div>


                      <div class="form-group col-xl-4 col-lg-4 p-2">
                          <span class="d-block align-items-center">
                              <InputLabel label="Quiz type" />
                              <span class="d-flex align-items-center px-2">
                                  <input type="checkbox" class="form-check-input" :id="`isQuizMcq_${quizIndex}`" name="type" true-value="mcq" false-value="saq" v-model="quiz.type" />
                                  <InputLabel label="Is multiple choice" class="px-2" :labelFor="`isQuizMcq_${quizIndex}`" />
                              </span>
                          </span>
                      </div>
                      <div class="form-group editor-sk col-xl-12 col-lg-12 p-2">
                          <label>{{ $t("Quiz Description") }}</label>
                          <text-editor v-model="quiz.description"></text-editor>
                          <input-errors :errors="errors" :name="`quizzes.${quizIndex}.description`" />
                      </div>
                  </div>

                  <!-- quiz end -->

                  <!-- quiz options start -->
                  <quiz-option-component :parent_quiz="quiz" :key="quizIndex" :quiz_type="quiz.type" :isCreateMode="isCreateMode" :old_options="quiz?.options" :errors="errors" :quiz_parent_name="`quizzes.${quizIndex}`" @options="handleChangeOptions" />
                  <!-- quiz options end -->
              </div>
          </div>
          <!-- quiz area -->

          <!-- add new lesson -->
          <p v-if="isCreateMode">
              <a @click.prevent="addNewQuiz" class="btn btn-sm btn-info mt-2" href="#">{{ $t("Add Quiz") }}</a>
          </p>
      </div>

      <div class="form-group">
                <div class="d-flex">
                    <button
                        class="btn btn-primary waves-effect waves-lightml-2 ic_mr-2 d-flex align-items-center"
                        type="button"
                        @click.prevent="submitAction"
                        :disabled="isLoading"
                    >
                        <div
                            class="spinner-border text-info spinner-border-sm"
                            :class="isLoading ? 'd-block' : 'd-none'"
                            role="status"
                        >
                            <span class="sr-only">{{ $t("Loading") }}...</span>
                        </div>

                        <i
                            class="fa fa-save"
                            :class="isLoading ? 'd-none' : ''"
                        ></i>
                        <span class="course_submit_btn">{{
                            $t("Submit")
                        }}</span>
                    </button>
                    <a
                        class="btn btn-danger waves-effect"
                        :href="data.cancelUrl"
                    >
                        <i class="fa fa-times"></i> {{ $t("Cancel") }}
                    </a>
                </div>
            </div>
  </div>
</template>

<script>
import InputGroup from "@/components/partials/inputGroup.vue";
import InputLabel from "@/components/partials/InputLabel.vue";
import CustomEditor from "@/components/summernote/CustomEditor.vue";
import QuizOptionComponent from "./QuizOptionComponent.vue";
import { v4 as uuidv4 } from "uuid";
import { findBy, notifyMe } from "@/helpers";

export default {
  components: { InputGroup, InputLabel, "text-editor": CustomEditor, QuizOptionComponent },

  props: ["data", "parents"],

  data() {
      return {
          errors: {},
          toolbarOptions: {
                placeholder: "Write here..",
                toolbar: [],
            },
          isCreateMode: this.data.isCreateMode || false,
          isLoading: false,
          dataEmptyQuiz: {
              id: uuidv4(),
              title: "",
              topic_id: this.parents?.id,
              type: "mcq",
              description: "",
              image: "",
              options: [],
          },
          quizzes: this.data.quizzes || [],
      };
  },

  methods: {
      addNewQuiz() {
          const newQuiz = { ...this.dataEmptyQuiz, id: uuidv4() };
          this.quizzes.push(newQuiz);
      },

      async confirmRemoveQuiz(quiz) {
          const result = await this.$swal({
              title: `${this.$t("Are you sure")}?`,
              text: `${this.$t("You won't be able to revert this")}!`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: `${this.$t("Yes, delete it")}!`,
          });

          if (result.isConfirmed) {
              this.removeQuiz(quiz);
          }
      },

      removeQuiz(quiz) {
          this.quizzes = this.quizzes.filter((q) => q.id !== quiz.id);
          axios
              .post(route("quizzes.delete", { id: quiz.id }))
              .then((res) => {
                  const { status } = res.data;
                  if (status === "success") {
                      this.$swal(`${this.$t("Deleted")}!`, `${this.$t("Item has been deleted")}.`, "success");
                  }
              })
              .catch((error) => {
                  console.error(error);
              });
      },

      async generateSlug(quiz) {

          if (quiz.title && quiz.title.length > 0) {
              try {
                  const res = await axios.post(this.data.slugGenerateUrlName, { title: quiz.title });
                  const { data, status } = res.data;
                  if (status === "success") {
                      quiz.slug = data;
                  }
              } catch (error) {
                  console.error(error);
              }
          }
      },

      handleChangeOptions(options) {
            const quizIndex = findBy(this.quizzes, options.quiz_id, "id");
            if (quizIndex >= 0) {
                this.quizzes[quizIndex].options = [...options.data];
            }
        },

        async submitAction() {
            this.isLoading = true;
            const { data, quizzes } = this;
            const self = this;
            const formData = {};
            formData.quizzes = quizzes.map((quiz) => {
                return {
                    ...quiz,
                    // options: [...quiz.options],
                };
            });
            formData.parent_id = this.parents?.id;
            const actionUrl = data.actionUrl;
            const method = data.method;
            try {
              await axios[method](actionUrl, formData)
              .then((res) => {
                const { status, message } = res.data;
                self.isLoading = false;
                if (status === "success") {
                  notifyMe(message, "success");
                  window.location.href = data.cancelUrl;
                }
              }).catch((error) => {
                const { data: allErrors = {} } = error?.response?.data;
                self.errors = allErrors;
              }).finally(() => {
                self.isLoading = false;
              });
            } catch (error) {
              self.isLoading = false;
            }
        },
  },

  mounted() {
    if (this.isCreateMode) {
        this.addNewQuiz();
    }
  },
};
</script>

<style scoped>
.single-quiz {
  background: #f6fefe;
  padding: 0.6rem;
  border-radius: 5px 5px 0 0;
}
.quiz-area {
  border: 0.5px solid lightgray;
  border-radius: 5px 5px 0 0;
}
.quiz_container {
  padding: 0 1rem;
}
ql-editor > b,
strong {
  font-weight: 600;
}
</style>
<style src="@vueform/toggle/themes/default.css"></style>
