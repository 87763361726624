<template>
    <div class="mt-2">
        <p class="header-title">
            {{ $t("Add Quiz for") }}
            <strong>{{ parent_topic?.title }}</strong>
        </p>

        <div
            class="quiz-area mb-2"
            v-for="(quiz, quizIndex) in quizzes"
            :key="quizIndex"
        >
            <p
                class="d-flex align-items-center justify-content-between single-quiz"
            >
                <a
                    data-bs-toggle="collapse"
                    :href="`#quiz${quizIndex + 1}`"
                    role="button"
                    aria-expanded="true"
                    :aria-controls="`quiz${quizIndex + 1}`"
                >

                    <span v-if="quiz.title">{{ quizIndex + 1 +". " }} </span> <span v-html="quiz.title"></span>
                    <!-- <span v-else
                        >Quiz
                        {{ quizIndex + 1 }}
                    </span> -->
                </a>
                <!-- topic id -->
                <div class="d-flex">
                    <a
                        v-if="quizIndex != 0"
                        @click.prevent="removeQuiz(quiz)"
                        class="text-danger text-bold"
                        href="#"
                        ><i class="ti-close"></i
                    ></a>
                </div>
            </p>
            <div
                class="collapse quiz_container show"
                :id="`quiz${quizIndex + 1}`"
            >

                <div class="row">
                    <!-- <InputGroup
                        class="col-xl-8 col-lg-8"
                        v-model="quiz.title"
                        label="Quiz title"
                        :name="`${
                            parent_name ? parent_name + '.' : ''
                        }quizzes.${quizIndex}.title`"
                        required
                        placeholder="Quiz title"
                        :errors="errors"
                        @focusout="generateSlug(quiz)"
                    /> -->

                    <div class="col-xl-8 col-lg-8">
                          <label>{{ $t("Quiz title") }}</label>
                          <text-editor v-model="quiz.title" :config="toolbarOptions" ></text-editor>

                          <input-errors
                            :errors="errors"
                            :name="`${parent_name ? parent_name + '.' : ''}quizzes.${quizIndex}.title`"
                        ></input-errors>
                    </div>

                    <div class="form-group col-xl-4 col-lg-4 p-2">
                        <span class="d-block align-items-center">
                            <InputLabel label="Quiz type" />
                            <span class="d-flex align-items-center px-2">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    :id="`isQuizMcq_${quizIndex}`"
                                    name="type"
                                    true-value="mcq"
                                    false-value="saq"
                                    v-model="quiz.type"
                                />
                                <InputLabel
                                    label="Is multiple choice"
                                    class="px-2"
                                    :labelFor="`isQuizMcq_${quizIndex}`"
                                />
                            </span>
                        </span>
                    </div>

                    <div class="form-group editor-sk col-xl-12 col-lg-12 p-2">
                        <label>{{ $t("Quiz Description") }} </label>

                        <text-editor v-model="quiz.description"></text-editor>

                        <input-errors
                            :errors="errors"
                            :name="`${parent_name ? parent_name + '.' : ''}quizzes.${quizIndex}.description`"
                        ></input-errors>
                    </div>

                    <div class="form-group col-xl-3 col-lg-3 p-2">
                        <div class="form-check form-switch">
                            <label class="form-check-label" for="switch1">{{ $t('Is show pdf?') }}</label>
                            <input v-model="quiz.is_show_pdf" class="form-check-input" type="checkbox" role="switch" id="switch1" checked="{{ quiz.is_show_pdf }}" true-value="1" false-value="0">
                        </div>
                    </div>

                    <div class="form-group col-xl-3 col-lg-3 p-2">
                        <div class="form-check form-switch">
                            <label class="form-check-label" for="switch2">{{ $t('Has calculator?') }}</label>
                            <input v-model="quiz.has_calculator" class="form-check-input" type="checkbox" role="switch" id="switch2" checked="{{ quiz.has_calculator }}" true-value="1" false-value="0">
                        </div>
                    </div>
                    <div class="form-group col-xl-3 col-lg-3 p-2">
                        <div class="form-check form-switch">
                            <label class="form-check-label" for="switch3">{{ $t('Is side show?') }}</label>
                            <input v-model="quiz.is_show_side" class="form-check-input" type="checkbox" role="switch" id="switch3"
                             true-value="1" false-value="0">
                        </div>
                    </div>
                    <div class="form-group col-xl-3 col-lg-3 p-2">
                        <div class="form-check form-switch">
                            <label class="form-check-label" for="switch4">{{ $t('Is show formula sheet?') }}</label>
                            <input v-model="quiz.is_show_formula_sheet" class="form-check-input" type="checkbox" role="switch" id="switch4"
                             true-value="1" false-value="0">
                        </div>
                    </div>

                    <div class="form-group col-xl-3 col-lg-3 p-2">
                        <div v-if="quiz.is_show_pdf && quiz.is_show_pdf == 1">
                            <input :id="`file-upload-${quiz.id}`" @change="pdfFileHandler($event, quiz)" name="file" type="file"/>
                        </div>
                        <p v-if="quiz.file"> <strong>{{ $t('Current File') }}:</strong> {{ quiz.file }}</p>
                    </div>
                    <div class="form-group col-xl-3 col-lg-3 p-2"></div>
                    <div class="form-group col-xl-3 col-lg-3 p-2"></div>
                    <div class="form-group col-xl-3 col-lg-3 p-2">
                        <div v-if="quiz.is_show_formula_sheet && quiz.is_show_formula_sheet == 1">
                            <input :id="`formula-upload-${quiz.id}`" @change="formulaSheetFileHandler($event, quiz)" name="formula_sheet" type="file"/>
                        </div>
                        <p v-if="quiz.formula_sheet"> <strong>{{ $t('Current File') }}:</strong> {{ quiz.formula_sheet }}</p>
                    </div>

                    {{ updateRenderCount(quizIndex + 1) }}

                </div>

                <!-- quiz end -->

                <!-- quiz options start -->
                <topic-quiz-option-component
                    :parent_quiz="quiz"
                    :key="quizIndex"
                    :quiz_type="quiz.type"
                    :isEditMode="isEditMode"
                    :old_options="quiz?.options"
                    :errors="errors"
                    :quiz_parent_name="`${
                        parent_name ? parent_name + '.' : ''
                    }quizzes.${quizIndex}`"
                    @options="handleChangeOptions"
                ></topic-quiz-option-component>
                <!-- quiz options end -->
            </div>
        </div>
        <!-- add new lesson -->
        <p v-if="is_permit_to_add_new_quiz">
            <a
                @click.prevent="addNewQuiz"
                class="btn btn-sm btn-info mt-2"
                href="#"
                >{{ $t("Add Quiz") }}</a
            >
        </p>
    </div>
</template>
<script>
import inputGroup from "@/components/partials/inputGroup.vue";
import InputLabel from "@/components/partials/InputLabel.vue";
import InputText from "@/components/partials/InputText.vue";
import { findBy } from "@/helpers";


import CustomEditor from "@/components/summernote/CustomEditor.vue";


import { v4 as uuidv4 } from "uuid";
import TopicQuizOptionComponent from "@/components/lesson/topic/TopicQuizOptionComponent.vue";


export default {

    components: { inputGroup, InputLabel, InputText, TopicQuizOptionComponent, 'text-editor':CustomEditor },

    props: {
        parent_topic: {
            default: {},
        },
        isEditMode: {
            default: false,
        },
        old_quizzes: {
            default: [],
        },
        parent_name: {
            default: '',
        },
        errors: {
            default: {},
        },
        is_permit_to_add_new_quiz: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            isActiveEditor:false,
            isLoading:false,
            dataLength:0,
            renderNumber:0,
            dataEmptyQuiz: {
                id: uuidv4(),
                title: "",
                topic_id: this.parent_topic?.id,
                type: "mcq",
                is_show_pdf: 0,
                file:'',
                has_calculator: 0,
                is_show_formula_sheet: 0,
                formula_sheet:'',
                is_show_side: 0,
                description: "",
                image: "",
                options: [],
            },
            quizzes: [],

            toolbarOptions: {
                placeholder: "Write here..",
                toolbar: [],
            },

            options:{
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        [{
                            'script': 'sub'
                        }, {
                            'script': 'super'
                        }],
                        [{
                            'list': 'ordered'
                        }, {
                            'list': 'bullet'
                        }],
                        ['image'],
                        ['formula'],
                    ],
                },
                theme:"snow",
                placeholder:"Write...."
            },
        };
    },

    methods: {
        // add new empty topic
        addNewQuiz() {
            this.dataEmptyQuiz.id = uuidv4();
            this.quizzes.push({ ...this.dataEmptyQuiz });
        },

        uploadFileHandler(e){

        },

        setContents(inputHtml, quiz){
            const searchRegExp = /class="katex-html"/gi;
            let outputhtml = inputHtml.replaceAll(searchRegExp, 'style="display:none"');
            quiz.description = outputhtml;
        },

        removeQuiz(quiz) {
            this.$swal({
                title: `${this.$t('Are you sure')}?`,
                text: `${this.$t("You won't be able to revert this")}!`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: `${this.$t("Yes, delete it")}!`
            }).then((result) => {
                if (result.isConfirmed) {
                    this.quizzes.splice(this.quizzes.indexOf(quiz), 1);

                    // action for delete
                    axios.post(route('quizzes.delete',{id:quiz.id, topic_id:this.parent_topic.id})).then(res => {
                        const { status } = res.data;
                        if (status == 'success') {
                            this.$swal(
                            `${this.$t("Deleted")}!`,
                            `${this.$t("Item has been deleted")}.`,
                            'success'
                            );
                        }
                    }).catch(function (error) {
                        console.log(error);
                    });

                }
            });
        },

        // generate slug for title
        async generateSlug(quiz) {
            if (quiz.title && quiz.title.length > 0) {
                const data = {
                    title: quiz.title,
                };
                await axios
                    .post(route("lesson.slug.generate", data))
                    .then((res) => {
                        const { data, status, message } = res.data;
                        if (status == "success") {
                            quiz.slug = data;
                        }
                    });
            }
        },

        handleChangeOptions(options) {
            const quizIndex = findBy(this.quizzes, options.quiz_id, "id");
            if (quizIndex >= 0) {
                this.quizzes[quizIndex].options = [...options.data];
            }
        },


        updateRenderCount(index){
            this.renderNumber = index;
        },

        pdfFileHandler(event, quiz){
            let fileToLoad = event.target.files[0];
            let form = new FormData();
            form.append('quiz_id', quiz.id);
            form.append('file', fileToLoad);
            axios.post(route('ged-exam-quizes.file.upload'), form).then(res => {
                const {file=null, file_url=null} = res?.data?.data;
                quiz.file = file;
                quiz.file_url = file_url;
            })

        },
        formulaSheetFileHandler(event, quiz){
            let fileToLoad = event.target.files[0];
            let form = new FormData();
            form.append('quiz_id', quiz.id);
            form.append('formula_sheet', fileToLoad);
            form.append('old_file', quiz.formula_sheet);
            axios.post(route('ged-exam-quizes.formula_sheet.upload'), form).then(res => {
                const {file=null, file_url=null} = res?.data?.data;
                quiz.formula_sheet = file;
                quiz.formula_sheet_url = file_url;
            })

        },


    },

    watch: {
        quizzes: {
            immediate: true,
            handler: function (newVal) {
                if (this.quizzes && this.quizzes.length > 0) {
                    const data = {
                        topic_id: this.parent_topic?.id,
                        data: this.quizzes,
                    };
                    this.$emit("quizzes", data);
                }
            },
            deep: true,
        },
    },

    mounted() {
        if (!this.isEditMode) {
            this.addNewQuiz();
        } else {
            if (this.old_quizzes && this.old_quizzes.length > 0) {
                this.dataLength = this.old_quizzes.length;
                this.quizzes = this.old_quizzes;
            }else{
                // this.addNewQuiz();
            }
        }

    },

    computed:{
        editor: function () {
        return this.$refs.myQuillEditor
      },

    //   isReady: function () {
    //         if (this.dataLength == this.renderNumber) {
    //             this.isLoading = false
    //             return true;
    //         } else {
    //             return false;
    //         }
    //     },
    }
};
</script>

<style scoped>
.single-quiz {
    background: #f6fefe;
    padding: 0.6rem;
    border-radius: 5px 5px 0 0;
}
.quiz-area {
    border: 0.5px solid lightgray;
    border-radius: 5px 5px 0 0;
}
.quiz_container {
    padding: 0 1rem;
}
ql-editor > b, strong {
    font-weight: 600;
}

/* input[type="file"] {
    display: none;
} */
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    width: max-content;
    border-radius: 5px 0px 0px 5px;
    border-right: 0px;
}
.customfileInput{
    border-radius: 0px 5px 5px 0px;
}
</style>
<style src="@vueform/toggle/themes/default.css"></style>
