<template>
    <div class="mb-2">
        <h5>{{ $t('Quiz options') }}</h5>

        <div v-for="(quizOption, optionIndex) in options" :key="optionIndex">
            
            <div class="row quiz_options" v-if="parent_quiz?.type == 'mcq'">
                <div class="col-xl-11 col-lg-11">
                    <div class="row">
                        <div class="col-xl-9 col-lg-9 p-2">
                            <text-editor v-model="quizOption.option_title"></text-editor>
                            
                            <input-errors
                                :errors="errors"
                                :name="`${quiz_parent_name}.options.${optionIndex}.option_title`"
                            ></input-errors>

                        </div>

                        <div class="form-group col-xl-3 col-lg-3 p-2">
                            <span class="d-block align-items-center">
                                <InputLabel label="Set correct answer" />
                                <span class="d-flex align-items-center px-2">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        :id="`quizOptionForMcq_${parent_quiz.id}_${optionIndex}`"
                                        :name="`${quiz_parent_name}.options.${optionIndex}.is_correct`"
                                        v-model="quizOption.is_correct"
                                        true-value="1"
                                        false-value="0"
                                    />
                                    <InputLabel
                                        label="is Correct answer"
                                        class="px-2"
                                        :labelFor="`quizOptionForMcq_${parent_quiz.id}_${optionIndex}`"
                                    />
                                </span>
                            </span>
                        </div>

                    </div>
                </div>
                <div class="col-xl-1 col-lg-1 option_close">
                    <a
                        v-if="optionIndex != 0"
                        @click.prevent="removeQuizOption(quizOption)"
                        class="text-muted text-bold"
                        href="#"
                        ><i class="ti-close"></i
                    ></a>
                </div>
            </div>
            <div v-else>
                <InputGroup
                    class="col-xl-9 col-lg-9 p-2"
                    v-model="quizOption.answer"
                    label="Quiz answer"
                    :name="`${quiz_parent_name}.options.${optionIndex}.answer`"
                    required
                    placeholder="Quiz answer here"
                    :errors="errors"
                />
            </div>
        </div>
        <a
            v-if="parent_quiz?.type == 'mcq'"
            @click.prevent="addNewQuizOption"
            class="btn btn-sm btn-info mt-2"
            href="#"
            >{{ $t("Add Quiz Option") }}</a
        >
    </div>
</template>
<script>
import InputGroup from "@/components/partials/inputGroup.vue";
import InputLabel from "@/components/partials/InputLabel.vue";
import CustomEditor from "@/components/summernote/CustomEditor.vue";
import { v4 as uuidv4 } from "uuid";

export default {
    components: { InputGroup, InputLabel, 'text-editor':CustomEditor },
    props: [
        "parent_quiz",
        "quiz_type",
        "isEditMode",
        "old_options",
        "quiz_parent_name",
        "errors",
    ],
    data() {
        return {
            myValue:'',
            toolbarOptions:{
                modules:{
                    toolbar:[
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'script': 'sub'}, { 'script': 'super' }],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        ['image'],
                        ['formula'],
                    ],

                },
                theme:"snow",
                placeholder: 'Write...',
            },
            dataEmptyOption: {
                id: uuidv4(),
                quiz_id: this.parent_quiz?.id,
                option_title: "",
                is_correct: false,
                answer: "",
                image: "",
            },
            options: [],
        };
    },

    methods: {
        // add new empty topic
        addNewQuizOption(createItemNumber = 1) {
            let i = 0;
            do {
                this.dataEmptyOption.id = uuidv4();
                this.options.push({ ...this.dataEmptyOption });
                i++;
            }
            while (i < parseInt(createItemNumber));
        },
        uploadFileHandler(e){},
        removeQuizOption(quizOption) {
            this.$swal({
                title: `${this.$t('Are you sure')}?`,
                text: `${this.$t("You won't be able to revert this")}!`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: `${this.$t("Yes, delete it")}!`
            }).then((result) => {
                if (result.isConfirmed) {
                    this.options.splice(this.options.indexOf(quizOption), 1);

                    // action for delete
                    axios.post(route('quiz-options.delete',{id:quizOption.id})).then(res => {
                        const { status } = res.data;
                        if (status == 'success') {


                            this.$swal(
                            `${this.$t("Deleted")}!`,
                            `${this.$t("Item has been deleted")}.`,
                            'success'
                            );
                        }
                    }).catch(function (error) {
                        console.log(error);
                    });

                }
            });
        },

        setOptionTitleContents(inputHtml, quizOption){
            const searchRegExp = /class="katex-html"/gi;
            let outputhtml = inputHtml.replaceAll(searchRegExp, 'style="display:none"');
            quizOption.option_title = outputhtml;
        },
    },

    watch: {
        options: {
            immediate: true,
            handler: function (newVal) {
                if (this.options.length > 0) {
                    const data = {
                        quiz_id: this.parent_quiz?.id,
                        data: this.options,
                    };
                    this.$emit("options", data);
                }
            },
            deep: true,
        },
        quiz_type: {
            handler: function (newVal) {
                if (newVal == "saq") {
                    this.options = [];
                    this.addNewQuizOption();
                }
            },
            deep: true,
        },
    },

    mounted() {
        if (!this.isEditMode) {
            this.addNewQuizOption(4);
        } else {
            if (this.old_options && this.old_options.length > 0) {
                this.options = [...this.old_options];
            }else{
                this.addNewQuizOption(4);
            }
        }
    },
};
</script>
<style scoped>
.quiz_options {
    background: #f7fcf2;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 0.4rem;
}
.option_close {
    display: flex;
    flex-direction: row-reverse;
}
.quizOptionRef{
    height: auto;
}
</style>
